.profile_image_container {
	border: 4px solid var(--profileBorder);
	width: 180px;
	height: 180px;
	border-radius: 50%;
	padding: 3px;
	position: relative;
	@media (min-width: 768px) and (max-width: 991px) {
		width: 130px;
		height: 130px;
	}
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 50%;
	}
	.profile_img_edit {
		width: 35px;
		height: 35px;
		border: 4px solid var(--profileBorder);
		border-radius: 50%;
		padding: 2px;
		position: absolute;
		bottom: 0px;
		right: 13px;
		background-color: var(--white);
		z-index: 1;
		cursor: pointer;
		> input {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 0;
			cursor: pointer;
		}
		svg {
			height: 77%;
			cursor: pointer;
		}
	}
}

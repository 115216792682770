.is_mobile {
	.custom_footer_container {
		padding: 15px;
		background-color: var(--white);
		box-shadow: 0px 0px 5px 0px var(--boxShadow);
		.navigational_footer_container {
			.navigational_footer_item {
				flex: 1 1 10px;
				position: relative;
				padding: 3px 5px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				min-height: 73px;
				&::before {
					content: "";
					position: absolute;
					bottom: -1px;
					left: 5px;
					width: 0;
					height: 4px;
					border-radius: 2px;
					background-color: var(--themeColor);
					transition: ease-in-out 300;
				}
				&.navigational_footer_item_active {
					&::before {
						width: calc(100% - 10px);
					}
				}
				.navigational_footer_item_svg {
					min-height: 23px;
				}
				img {
					margin-bottom: 6px;
				}
				.navigational_footer_item_label {
					color: var(--themeColor);
					text-align: center;
					font-size: 16px;
				}
			}
		}
	}
}

.is_mobile {
	.product_form_container {
		.product_form_label {
			color: var(--black);
			margin-bottom: 5px;
		}
		.product_form_input_container {
			width: 100%;
			> input {
				width: 100%;
				border-radius: 2px;
				border: 1px solid var(--themeColor);
				background-color: var(--white);
				height: 32px;
				padding: 0px 10px;
				outline: none !important;
				box-shadow: none !important;
			}
			textarea {
				width: 100%;
				border-radius: 2px;
				border: 1px solid var(--themeColor);
				background-color: var(--white);
				padding: 10px;
				outline: none !important;
				box-shadow: none !important;
				height: 85px;
				display: inline-block;
				vertical-align: text-bottom;
			}
			.ant-select {
				.ant-select-selector {
					border: 1px solid var(--themeColor) !important;
					box-shadow: none !important;
				}
				.ant-select-arrow .anticon {
					color: var(--themeColor);
				}
			}
			.react_select {
				> div:nth-of-type(1) {
					border: 1px solid var(--themeColor);
					box-shadow: none !important;
					min-height: 32px;
					border-radius: 2px;
					background-color: var(--white);
					cursor: pointer;
					> div:nth-of-type(1) {
						padding: 0px 10px;
						> div:nth-of-type(1) {
							color: var(--black);
						}
					}
					> div:nth-of-type(2) {
						// padding: 0px 10px;
						// font-size: 16px;
						padding: 0px;
						> span:nth-of-type(1) {
							display: none;
						}
						> div:nth-of-type(1) {
							padding: 4px 10px;
						}
					}
				}
			}
			.ant-picker {
				box-shadow: none !important;
				outline: none !important;
				border: 1px solid var(--themeColor);
			}
		}
	}
	.custom_carousel_container {
		.custom_carousel_main_img_container {
			margin-bottom: 15px;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			img {
				max-width: 100%;
			}
			.custom_carousel_delete_container {
				position: absolute;
				top: 10px;
				right: 10px;
				width: 40px;
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: rgba(174, 166, 166, 0.5);
				border-radius: 50%;
				padding: 5px;
				cursor: pointer;
				svg {
					width: 100%;
					height: 100%;
				}
			}
		}
		.custom_carousel_slider_container {
			display: flex;
			align-items: center;
			.custom_carousel_slide_item {
				flex: 0 0 20%;
				padding: 0px 9px;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 70px;
				overflow: hidden;
				&.custom_carousel_slide_item_active {
					img {
						transform: scale(1.2);
					}
				}
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					transition: transform 0.3s;
				}
			}
		}
	}
}
.is_mobile_modal {
	.product_created_modal {
		.ant-modal-close {
			display: none;
		}
	}
	.product_created_modal_content {
		padding: 10px;
		text-align: center;
		min-height: 230px;
		.product_created_modal_head {
			font-size: 24px;
			color: var(--themeColor);
		}
		.product_created_modal_dec {
			color: var(--black);
		}
	}
}

.is_web {
	.upload_mode_container {
		height: calc(100% - (66px));
		overflow: auto;
		&.upload_mode_container_mb_3 {
			height: calc(100% - (82px));
		}
		.upload_mode_capture_box {
			padding: 50px 30px;
			background-color: var(--white);
			box-shadow: 0px 1px 4px 0px var(--boxShadow);
			border-radius: 8px;
			width: 90%;
			min-width: 200px;
			overflow: auto;
			.upload_mode_note {
				color: var(--disabledText);
				font-size: 14px;
			}
		}
	}
}

.is_mobile {
	.farmer_details {
		overflow: auto;
	}
	.min_width_258 {
		min-width: 258px;
	}
	.reset_password_farmer_layout {
		min-height: calc(100% - 742px);
		.reset_password_farmer_container {
			background-color: var(--resetPasswordBg);
			border-radius: 2px;
			padding: 10px 16px;
		}
	}
}

.is_mobile {
	.generate_token_container {
		.generate_heading {
			font-size: 22px;
			color: var(--black);
			margin-bottom: 15px;
			border-bottom: 1px solid var(--loginInputBorder);
			padding: 16px 16px 5px 16px;
		}
		.generate_token_content {
			padding: 16px;
			height: calc(100% - 73px);
			overflow: auto;
		}
	}
}

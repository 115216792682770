.is_mobile {
	.profile_page_container {
		padding: 16px;
		.user_details_container {
			.user_details_input {
				background: var(--white);
				box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.35);
				border-radius: 2px;
				min-height: 32px;
				padding: 0px 10px;
				line-height: 32px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				cursor: not-allowed;
			}
		}
		.profile_logout_btn {
			background: var(--white);
			box-shadow: 0px 1px 6px 0px #2c5638 !important;
			border-radius: 9px;
			outline: none !important;
			min-width: 180px;
			color: var(--themeColor);
			padding: 10px 20px;
			border: none;
		}
	}
}

.item_create_btn {
    display: flex;
    align-items: center;
    border-radius: 16px;
    padding: 0;
    min-width: 100px;
    background-color: var(--themeColor);
    color: var(--white);
    padding: 5px 7px;
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid var(--themeColor);
    min-height: 32px;
    &.disabled_btn {
        opacity: 0.5;
        cursor: not-allowed;
    }
    &:disabled,
    &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
    }
    .button_spinner {
        width: 24px;
        height: 24px;
        position: relative;
        top: 2px;
        div {
            border: 3px solid var(--white);
            border-color: var(--white) transparent transparent transparent;
        }
    }
    .icon {
        display: flex;
        flex: 0 0 20px;
        align-items: center;
        justify-content: center;
    }
    .btn_text {
        flex: 1 1 10px;
        align-items: center;
        justify-content: center;
    }
}
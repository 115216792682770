:root {
	--themeColor: #3e724c;
	--boxShadow: #00000029;
	--themeBgImgShader: #2f653d;
	--normalShade: #d9d9d9;
	--themeBoxShadow: #3e724c;
	--themeWhiteBorder: rgba(254, 254, 254, 0.25);
	--mainBodyBg: #f2f3f3;
	--mainHeaderBorder: #9e9e9e;
	--disabledText: #8f9499;
	--videoShade: #292929;
	--themeDarkColor: #2c5638;
	--black: #000000;
	--shadeText: hsba(137, 10%, 38%, 1);
	--loginText: hsba(0, 0%, 40%, 1);
	--loginInputBorder: #6e8b76;
	--invalid: rgb(246, 95, 95);
	--resetPasswordBg: #f3f3f3;
	--profileBorder: #b4dca0;
}

.is_web {
	.main_container {
		height: calc(100% - 65px);
		display: flex;
		overflow: auto;
		.main_content_container {
			flex: 1 1 10px;
			height: 100%;
			overflow: auto;
			.main_content_section_capture {
				height: 100%;
				overflow: auto;
				padding: 16px;
				background-color: var(--mainBodyBg);
				.upload_mode_image_capture_box {
					padding: 20px;
					.image_capture_container {
						height: calc(100% - 47px);
						position: relative;
						.capture_button  {
							position: absolute;
							bottom: -57px;
							left: calc(50% - 40px);
							width: 80px;
							height: 80px;
							padding: 5px;
							border-radius: 50%;
							box-shadow: 0px 0px 4px 0px var(--boxShadow);
							cursor: pointer;
							display: flex;
							align-items: center;
							justify-content: center;
							background-color: var(--white);
							&.disabled_capture_btn {
								cursor: no-drop;
								opacity: 0.7;
							}
							> svg {
								height: 40px;
							}
						}
					}
					.main_footer_section_capture {
						padding-top: 15px;
					}
				}
			}
			&.main_content_container_no_footer {
				background-color: var(--mainBodyBg);
			}
			.main_content_section {
				height: calc(100% - 60px);
				overflow: auto;
				padding: 16px;
				background-color: var(--mainBodyBg);
			}
			.main_content_heading {
				padding: 10px 0px;
				font-size: 22px;
				color: var(--themeColor);
				border-bottom: 1px solid var(--mainHeaderBorder);
				margin-bottom: 10px;
			}
			.main_footer_section {
				padding: 14px 15px;
			}
		}
	}
}
.is_web_modal {
	.main_content_container {
		.main_content_section_capture {
			height: calc(100% - 47px);
			.upload_mode_container {
				height: 100%;
				.upload_mode_image_capture_box {
					padding: 20px 0px;
					height: 100%;
					.image_capture_container {
						height: 100%;
						position: relative;
						.capture_button  {
							position: absolute;
							bottom: -57px;
							left: calc(50% - 40px);
							width: 80px;
							height: 80px;
							padding: 5px;
							border-radius: 50%;
							box-shadow: 0px 0px 4px 0px var(--boxShadow);
							cursor: pointer;
							display: flex;
							align-items: center;
							justify-content: center;
							background-color: var(--white);
							&.disabled_capture_btn {
								cursor: no-drop;
								opacity: 0.7;
							}
							> svg {
								height: 40px;
							}
						}
					}
					.main_footer_section_capture {
						padding-top: 15px;
					}
				}
			}
			
		}
	}
}

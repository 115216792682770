.is_mobile {
	.main_container {
		height: calc(100% - 127px);
		overflow: auto;
		&.main_container_transparent_header {
			height: calc(100% - 74px);
		}
		&.main_container_navigate_footer {
			height: calc(100% - 139px);
		}
		&.main_container_double_layer_footer {
			height: calc(100% - 167px);
		}
	}
}

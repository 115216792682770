.is_web {
	.upload_mode_container {
		.upload_mode_image_capture_box {
			padding: 30px 30px;
			background-color: var(--white);
			box-shadow: 0px 1px 4px 0px var(--boxShadow);
			border-radius: 8px;
			width: 90%;
			min-width: 200px;
			height: 100%;
			.image_capture_container {
				height: 100%;
				video {
					height: 100%;
					width: 100%;
					object-fit: fill;
				}
			}
			.image_capture_container_img {
				max-height: 100%;
				max-width: 100%;
				object-fit: fill;
			}
		}
	}
	.upload_capture_box_footer {
		width: 90%;
	}
}
.is_web_modal {
	.upload_mode_container {
		.upload_mode_image_capture_box {
			padding: 30px 30px;
			background-color: var(--white);
			border-radius: 8px;
			width: 90%;
			min-width: 200px;
			height: 100%;
			.image_capture_container {
				height: 100%;
				video {
					height: 100%;
					width: 100%;
					object-fit: fill;
				}
			}
			.image_capture_container_img {
				max-height: 100%;
				max-width: 100%;
				object-fit: fill;
			}
		}
	}
	.upload_capture_box_footer {
		width: 90%;
	}
}
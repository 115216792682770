.is_mobile {
	.upload_mode_container {
		.upload_mode_capture_box {
			min-height: calc(100% - 160px);
			background-color: var(--themeBgImgShader);
			background-image: url("../../../../public/images/main_bg.png");
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			padding: 85px 15px 20px 15px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			flex-direction: column;
			overflow: auto;
		}
		.upload_mode_gallery_box {
			height: 160px;
			padding: 20px 15px;
		}
		.svg_container_btn {
			svg {
				height: 30px;
			}
		}
	}
	.upload_mode_btn {
		min-width: 235px;
		justify-content: space-between !important;
	}
}

.is_mobile {
	.image_capture_container {
		height: 100%;
		video {
			height: 100%;
			width: 100%;
			object-fit: fill;
		}
	}
	.image_capture_container_img {
		max-height: 100%;
		max-width: 100%;
		object-fit: fill;
	}
}
.is_mobile_modal {
	.image_capture_container {
		height: 100%;
		video {
			height: 100%;
			width: 100%;
			object-fit: fill;
		}
	}
	.image_capture_container_img {
		max-height: 100%;
		max-width: 100%;
		object-fit: fill;
	}
}
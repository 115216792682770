.is_web {
	.sidebar_container {
		width: 300px;
		flex: 0 0 300px;
		height: 100%;
		background-color: var(--themeColor);
		padding: 0px 10px;
		.sidebar_content_container {
			border-top: 1px solid var(--themeWhiteBorder);
			padding: 15px 5px;
			height: calc(100% - 56px);
			.sidebar_item_container {
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-bottom: 1px solid var(--themeWhiteBorder);
				padding: 15px 10px;
				color: var(--white);
				font-size: 16px;
				cursor: pointer;
				.sidebar_item_icon {
					display: flex;
					> svg {
						height: 21px;
					}
				}
				> svg {
					display: none;
					font-size: 22px;
				}
				&.sidebar_item_active_container {
					> svg {
						display: inline-block;
					}
				}
			}
		}
		.sidebar_logout {
			text-align: center;
			.logout_btn {
				border: 1px solid var(--white);
				background-color: transparent;
				border-radius: 4px;
				height: 32px;
				padding: 0px 15px;
				color: var(--white);
				outline: none !important;
				box-shadow: none !important;
				min-width: 100px;
				font-size: 14px;
				svg {
					margin-right: 15px;
				}
			}
		}
	}
}

.transition_modal {
	.ant-modal-confirm-body {
		justify-content: center;
	}
}
.is_web {
	.farmer_create_form {
		height: calc(100% - 66px);
		overflow: auto;
		.farmer_form_label {
			flex: 0 0 170px;
			font-size: 15px;
		}
		&.farmer_profile_container {
			.farmer_form_input_container {
				max-width: initial !important;
			}
		}
		.farmer_form_input_container {
			width: 10px;
			flex: 1 1 10px;
			max-width: 500px;
			> input {
				width: 100%;
				border-radius: 4px;
				border: none;
				background-color: var(--white);
				height: 32px;
				padding: 0px 10px;
				outline: none !important;
				box-shadow: 0px 0px 2px 0px var(--boxShadow) !important;
			}
			textarea {
				width: 100%;
				border-radius: 4px;
				border: none;
				background-color: var(--white);
				padding: 10px;
				outline: none !important;
				box-shadow: 0px 0px 2px 0px var(--boxShadow) !important;
				height: 100px;
				display: inline-block;
				vertical-align: top;
			}
			.ant-select {
				.ant-select-selector {
					border: none !important;
					box-shadow: 0px 0px 2px 0px var(--boxShadow) !important;
				}
				.ant-select-arrow .anticon {
					color: var(--themeColor);
				}
			}
			.react_select {
				> div:nth-of-type(1) {
					border: none !important;
					box-shadow: 0px 0px 2px 0px var(--boxShadow) !important;
					min-height: 32px;
					border-radius: 4px;
					background-color: var(--white);
					cursor: pointer;
					> div:nth-of-type(1) {
						padding: 0px 10px;
						> div:nth-of-type(1) {
							color: var(--black);
						}
					}
					> div:nth-of-type(2) {
						// padding: 0px 10px;
						// font-size: 16px;
						padding: 0px;
						> span:nth-of-type(1) {
							display: none;
						}
						> div:nth-of-type(1) {
							padding: 4px 10px;
						}
					}
				}
			}
		}
	}
	.reset_password_container {
		background-color: #d9d9d9;
		border-radius: 12px;
		.farmer_form_label {
			flex: 0 0 170px;
			font-size: 15px;
		}
		.farmer_form_input_container {
			width: 10px;
			flex: 1 1 10px;
			> input {
				width: 100%;
				border-radius: 4px;
				border: none;
				background-color: var(--white);
				height: 32px;
				padding: 0px 10px;
				outline: none !important;
				box-shadow: 0px 0px 2px 0px var(--boxShadow) !important;
			}
		}
	}
}

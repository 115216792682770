.is_web {
	.custom_header {
		display: flex;
		background-color: var(--white);
		color: var(--white);
		min-height: 65px;
		position: relative;
		.header_logo_container {
			background-color: var(--themeColor);
			padding: 15px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 300px;
			height: 100%;
			min-height: 65px;
			font-size: 22px;
		}
		.header_main_content {
			flex: 1 1 10px;
			padding: 3px 15px;
			box-shadow: 0px 1px 2px var(--boxShadow);
			.header_user_drop_down {
				display: flex;
				align-items: center;
				min-width: 25px;
				.header_user_drop_down_logo {
					background: var(--normalShade);
					box-shadow: inset 0px 0px 4px var(--themeBoxShadow);
					display: inline-block;
					border-radius: 50%;
					padding: 4px;
				}
				.header_user_drop_down_label {
					color: var(--themeColor);
					padding: 0px 10px;
					font-size: 16px;
				}
			}
			.profile_pic_container {
				width: 45px;
				height: 45px;
				border-radius: 50%;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					border-radius: 50%;
				}
			}
		}
	}
}

.is_web {
	.product_create_container {
		height: calc(100% - 66px);
		overflow: auto;
		padding: 14px 7px;
		.product_form {
			flex: 1 1 65%;
			min-width: 200px;
			.product_form_label {
				flex: 0 0 120px;
				font-size: 15px;
				&.flex_0_0_200 {
					flex: 0 0 200px;
					@media (max-width: 1100px) and (min-width: 768px) {
						flex: 0 0 110px !important;
					}
				}
			}
			.product_form_input_container {
				flex: 1 1 10px;
				width: 10px;
				> input {
					width: 100%;
					border-radius: 4px;
					border: none;
					background-color: var(--white);
					height: 32px;
					padding: 0px 10px;
					outline: none !important;
					box-shadow: 0px 0px 2px 0px var(--boxShadow) !important;
				}
				textarea {
					width: 100%;
					border-radius: 4px;
					border: none;
					background-color: var(--white);
					padding: 10px;
					outline: none !important;
					box-shadow: 0px 0px 2px 0px var(--boxShadow) !important;
					height: 100px;
					display: inline-block;
					vertical-align: top;
				}
				.ant-select {
					.ant-select-selector {
						border: none !important;
						box-shadow: 0px 0px 2px 0px var(--boxShadow) !important;
					}
					.ant-select-arrow .anticon {
						color: var(--themeColor);
					}
				}
				.react_select {
					> div:nth-of-type(1) {
						border: none !important;
						box-shadow: 0px 0px 2px 0px var(--boxShadow) !important;
						min-height: 32px;
						border-radius: 4px;
						background-color: var(--white);
						cursor: pointer;
						> div:nth-of-type(1) {
							padding: 0px 10px;
							> div:nth-of-type(1) {
								color: var(--black);
							}
						}
						> div:nth-of-type(2) {
							// padding: 0px 10px;
							// font-size: 16px;
							padding: 0px;
							> span:nth-of-type(1) {
								display: none;
							}
							> div:nth-of-type(1) {
								padding: 4px 10px;
							}
						}
					}
				}
				.ant-picker {
					box-shadow: 0px 0px 2px 0px var(--boxShadow) !important;
					outline: none !important;
					border: none !important;
				}
			}
		}
		.product_carousel {
			flex: 1 1 35%;
			min-width: 400px;
			@media (max-width: 1100px) and (min-width: 768px) {
				min-width: auto;
			}
			background-color: var(--white);
			padding: 10px;
			border-radius: 7px;
			box-shadow: 0px 1px 4px var(--boxShadow);
			&.product_carousel_full_height {
				.custom_carousel_container {
					height: calc(100%);
				}
			}
			.custom_carousel_container {
				height: calc(100% - (32px + 0.5rem));
				.custom_carousel_main_img_container {
					display: flex;
					align-items: center;
					justify-content: center;
					height: calc(100% - 70px);
					position: relative;
					> img {
						max-width: 100%;
						max-height: 100%;
						object-fit: contain;
					}
					.custom_carousel_delete_container {
						position: absolute;
						top: 10px;
						right: 10px;
						width: 40px;
						height: 40px;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: rgba(174, 166, 166, 0.5);
						border-radius: 50%;
						padding: 5px 5px 6px 2px;
						cursor: pointer;
						svg {
							width: 100%;
							height: 100%;
						}
					}
				}

				.custom_carousel_slider_container {
					display: flex;
					align-items: center;
					height: 70px;
					.custom_carousel_slide_item {
						flex: 0 0 20%;
						padding: 10px 7.5px 7.5px 7.5px;
						display: flex;
						align-items: center;
						justify-content: center;
						height: 70px;
						overflow: hidden;
						cursor: pointer;
						&.custom_carousel_slide_item_active {
							img {
								transform: scale(1.2);
							}
						}
						&:hover {
							img {
								transform: scale(1.2);
							}
						}
						img {
							height: 100%;
							width: 100%;
							object-fit: cover;
							transition: transform 0.3s;
						}
					}
				}
			}
		}
	}
}
.is_web_modal {
	&.product_created_modal {
		.ant-modal-close {
			display: none;
		}
	}
	&.product_details_modal {
		width: calc(100% - 30px) !important;
		max-width: 1000px;
		height: calc(100% - 30px);
		max-height: 500px !important;
		.ant-modal-close {
			display: flex;
			align-items: center;
			justify-content: center;
			.ant-modal-close-x {
				display: flex !important;
			}
		}
		.ant-modal-content {
			height: 100%;
			.ant-modal-body {
				height: 100%;
				width: 100%;
				.main_content_container {
					height: 100%;
					.main_content_section_modal {
						height: calc(100%) !important;			
					}
					.main_content_section {
						height: calc(100% - 32px);	
						.upload_mode_container  {
							height: 100%;
							.upload_mode_capture_box  {
								width: 100%;
							}
							.upload_mode_image_capture_box {
								height: 100%;
							}
						}
					}
				}	
			}
		}
	}
	.product_created_modal_content {
		padding: 10px;
		text-align: center;
		min-height: 230px;
		.product_created_modal_head {
			font-size: 22px;
			color: #4f4f4f;
		}
		.product_created_modal_dec {
			color: var(--black);
		}
	}
}

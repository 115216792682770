.is_web {
	.upload_mode_container {
		.upload_mode_browse_box {
			min-height: 100%;
			.image_upload_choose_file_container {
				.choose_file_input {
					opacity: 0;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
				}
				.choose_file_box {
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					word-break: break-all;
					word-break: break-word;
					white-space: pre-wrap;
				}
			}
		}
	}
}
.is_web_modal {
	.upload_mode_container {
		.upload_mode_browse_box {
			min-height: 100%;
			.image_upload_choose_file_container {
				.choose_file_input {
					opacity: 0;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
				}
				.choose_file_box {
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					word-break: break-all;
					word-break: break-word;
					white-space: pre-wrap;
				}
			}
		}
	}
}

.is_mobile {
	.farmer_create_form {
		.farmer_form_label {
			color: var(--black);
			margin-bottom: 5px;
			padding-left: 3px;
		}
		.farmer_form_input_container {
			width: 100%;
			> input {
				width: 100%;
				border-radius: 2px;
				border: 1px solid var(--themeColor);
				background-color: var(--white);
				height: 32px;
				padding: 0px 10px;
				outline: none !important;
				box-shadow: none !important;
				&.textarea_input {
					height: 72px;
				}
			}
			textarea {
				width: 100%;
				border-radius: 2px;
				border: 1px solid var(--themeColor);
				background-color: var(--white);
				padding: 10px;
				outline: none !important;
				box-shadow: none !important;
				height: 100px;
				display: inline-block;
				vertical-align: top;
			}
			.ant-select {
				.ant-select-selector {
					border: 1px solid var(--themeColor) !important;
					box-shadow: none !important;
				}
				.ant-select-arrow .anticon {
					color: var(--themeColor);
				}
			}
			.react_select {
				> div:nth-of-type(1) {
					border: 1px solid var(--themeColor);
					box-shadow: none !important;
					min-height: 32px;
					border-radius: 2px;
					background-color: var(--white);
					cursor: pointer;
					> div:nth-of-type(1) {
						padding: 0px 10px;
						> div:nth-of-type(1) {
							color: var(--black);
						}
					}
					> div:nth-of-type(2) {
						// padding: 0px 10px;
						// font-size: 16px;
						padding: 0px;
						> span:nth-of-type(1) {
							display: none;
						}
						> div:nth-of-type(1) {
							padding: 4px 10px;
						}
					}
				}
			}
		}
	}
}

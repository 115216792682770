.is_mobile {
	.image_upload_choose_file_container {
		height: 100%;
		.choose_file_input {
			opacity: 0;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}
		.choose_file_box {
			background-color: var(--normalShade);
			color: var(--themeColor);
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 15px;
			word-break: break-all;
			word-break: break-word;
			white-space: pre-wrap;
		}
	}
}
.is_mobile_modal {
	.image_upload_choose_file_container {
		height: 100%;
		.choose_file_input {
			opacity: 0;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}
		.choose_file_box {
			background-color: var(--normalShade);
			color: var(--themeColor);
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 15px;
			word-break: break-all;
			word-break: break-word;
			white-space: pre-wrap;
		}
	}
}

.is_mobile {
	.product_list_container {
		height: 100%;
		overflow: auto;
		padding: 16px;
		&.item_list_container {
			height: calc(100% - 48px);
		}
		.product_list_item_container {
			background-color: var(--white);
			box-shadow: 0px 0px 4px var(--boxShadow);
			border-radius: 4px;
			padding: 10px;
			display: flex;
			align-items: center;
			margin-bottom: 10px;
			min-height: 68px;
			.product_list_item_img_container {
				flex: 0 0 60px;
				height: 100%;
				overflow: hidden;
				display: flex;
				align-items: center;
				justify-content: center;
				padding-right: 10px;
				img {
					max-width: 100%;
					border-radius: 4px;
				}
			}
			.product_list_item_label_container {
				flex: 1 1 10px;
				width: 10px;
				word-break: break-all;
				word-break: break-word;
				white-space: pre-wrap;
				.product_list_item_label_main {
					color: var(--themeDarkColor);
					font-size: 20px;
				}
				.product_list_item_label_sub {
					font-size: 14px;
				}
			}
			.product_list_item_more {
				&.admin_product_list_item_more {
					flex: 0 0 70px;
				}
				flex: 0 0 25px;
				svg {
					font-size: 20px;
					color: var(--themeDarkColor);
				}
			}
		}
	}
}

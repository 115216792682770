.is_mobile {
	.admin_product_list_select {
		&.react_select {
			> div:nth-of-type(1) {
				border: none !important;
				box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
				border-radius: 2px;
				min-height: 32px;
				background-color: var(--white);
				cursor: pointer;
				> div:nth-of-type(1) {
					padding: 0px 0px 0px 10px;
					> div:nth-of-type(1) {
						color: var(--black);
					}
				}
				> div:nth-of-type(2) {
					// padding: 0px 10px;
					// font-size: 16px;
					padding: 0px;
					> span:nth-of-type(1) {
						display: none;
					}
					> div {
						padding: 4px 6px;
						&:last-child {
							padding-right: 10px;
						}
						svg {
							color: var(--black);
						}
					}
				}
			}
		}
	}
	.admin_product_list_container {
		height: calc(100% - 48px);
	}
}

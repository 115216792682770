.is_mobile {
	.login_container {
		background-image: url("../../../../public/images/mobile-images/login_image.png");
		background-position: bottom;
		background-size: 100%, cover;
		background-repeat: no-repeat;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		.login_card {
			width: 100%;
			max-width: 400px;
			padding: 15px 15px;
			@media screen and (max-width: 312px) {
				padding: 15px 10px;
			}
			.login_heading {
				text-align: center;
				color: var(--themeColor);
				font-size: 26px;
			}
			.login_head_dec {
				font-size: 14px;
				color: var(--shadeText);
				text-align: center;
			}
			.login_input_container {
				.login_input_label {
					font-size: 14px;
					color: var(--loginText);
				}
				input {
					border: 1px solid var(--loginInputBorder);
					border-radius: 6px;
					height: 34px;
					width: 100%;
					background-color: transparent;
					outline: none !important;
					box-shadow: none !important;
					padding: 0px 15px;
					background-color: var(--white);
				}
				.password_eye_container {
					> input {
						padding-right: 30px;
					}
					.password_eye {
						position: absolute;
						top: 8px;
						right: 10px;
						color: var(--themeColor);
					}
				}
			}
			.login_remember_me {
				color: var(--themeColor);
				font-size: 11px;
				@media screen and (max-width: 400px) {
					font-size: 10px;
				}
				display: flex;
				align-items: center;
				> input {
					background-color: var(--themeColor);
					cursor: pointer;
					&[type="checkbox"] {
						accent-color: var(--themeColor);
					}
				}
				.ant-checkbox-wrapper {
					.ant-checkbox {
						.ant-checkbox-inner {
							background-color: #e3efe6;
							border: 1px solid var(--themeColor);
							border-radius: 2px;
						}
						&.ant-checkbox-checked {
							.ant-checkbox-inner {
								background-color: var(--themeColor);
							}
							&:after {
								border-color: var(--themeColor);
							}
						}
					}
				}
			}
			.forgot_password_label {
				color: #1673ca;
				text-decoration: underline;
				font-size: 11px;
				@media screen and (max-width: 400px) {
					font-size: 10px;
				}
			}
			.login_btn {
				width: 100%;
				max-width: 500px;
				border-radius: 10px;
				min-height: 34px;
			}
		}
	}
}

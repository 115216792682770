.is_mobile {
	.custom_header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: var(--themeColor);
		background-image: url("../../../../public/images/header-bg.png");
		background-position: center;
		background-size: contain;
		background-repeat: repeat;
		color: var(--white);
		padding: 3px 15px;
		min-height: 65px;
		&.transparent_header {
			background-color: transparent;
			background-image: none;
			@media screen and (max-height: 572px) {
				background-color: var(--themeColor);
			}
		}
		&.fixed_header {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
		}
		&.user_img_header {
			.header_back_nav_box {
				display: none;
			}
			.header_user_container {
				display: block !important;
				img {
					height: 50px;
				}
			}
			.header_back_nav_box_filler {
				flex: 0 0 50px;
			}
		}
		.profile_pic_container {
			width: 50px;
			height: 50px;
			border-radius: 50%;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 50%;
			}
		}
		.header_back_nav_box {
			flex: 0 0 40px;
			font-size: 27px;
		}
		.header_back_nav_box_filler {
			flex: 0 0 40px;
		}
		.header_text {
			color: var(--white);
			font-size: 20px;
			@media screen and (max-width: 380px) {
				font-size: 18px;
			}
		}
	}
}
